<template>
  <el-dialog
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      width="40%"
      :title="'pc端权限'"
      center
      @close="close">
    <el-row>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="页面权限" name="page">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="moduleTree"
                show-checkbox
                node-key="id"
                ref="moduleTree"
                :check-strictly="false"
                :default-checked-keys="processModule"
                :props="moduleProps">
            </el-tree>
          </div>
        </el-tab-pane>
        <el-tab-pane label="按钮权限" name="button">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="buttonTree"
                show-checkbox
                node-key="id"
                ref="buttonTree"
                :check-strictly="false"
                :default-checked-keys="processButton"
                :props="buttonProps">
            </el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "grade-pc-permission",
  created() {
    this.dialogState=this.state;
    this.queryAllModuleTree()
    this.queryAllButtonTree()
  },
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true
    },
    gradeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogState: false,
      activeName: "page",
      moduleTree: [],
      processModule: [],
      moduleProps: {
        children: 'child',
        label: 'title',
      },
      buttonTree: [],
      processButton: [],
      buttonProps: {
        label: "buttonName"
      },
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    submit() {
      this.$confirm("是否修该产品电脑端视图和按钮？",
          "修改产品",{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
        this.savaDefaultPermission()
      }).catch(() => {})
    },
    //查询所有模块
    queryAllModuleTree() {
      this.$axios({
        method:"GET",
        url:"/module/queryModuleTreeByViewFilter",
      }).then(response=>{
        this.moduleTree=response.data.data
        this.queryPCModuleIdsByGradeId()
      })
    },
    //根据产品ID查询页面id并填充
    queryPCModuleIdsByGradeId() {
      this.$axios({
        method: "GET",
        url: "/productGrade/queryPCModuleIdsByGradeId",
        params: {
          id: this.gradeId,
        }
      }).then(response=>{
        this.moduleTree.forEach(k=>{
          k.child.forEach(v=>{
            let indexOf1=response.data.data.indexOf(k.id)
            //如果不包含二级节点 则删除一级节点
            if (!response.data.data.includes(v.id)){
              if (indexOf1!==-1)
                response.data.data.splice(indexOf1,1)
            }
            v.child.forEach(s=>{
              let indexOf=response.data.data.indexOf(v.id)
              //如果不包含三级节点 则删除二级节点,当二级节点被删除时,一级节点自动变成半选中
              if (!response.data.data.includes(s.id)){
                if (indexOf!==-1)
                  response.data.data.splice(indexOf,1)
              }
            })
          })
        })
        this.processModule=response.data.data
        console.log(response)
      })
    },
    //根据产品ID查询按钮id并填充
    queryButtonIdsByGradeIdAndType() {
      this.$axios({
        method: "GET",
        url: "/productGrade/queryButtonIdsByGradeIdAndType",
        params: {
          id: this.gradeId,
          type: 1,
        }
      }).then(response=>{
        this.processButton=response.data.data
      })
    },
    queryAllButtonTree() {
      this.$axios({
        method: "GET",
        url: "/button/queryAllButtonTree",
        params: {
          type: 1,
        }
      }).then(response=>{
        this.buttonTree = response.data.data
        this.queryButtonIdsByGradeIdAndType()
      })
    },
    savaDefaultPermission() {
      //获取全部树中的虚拟节点
      let fakeButtonIds=this.buttonTree.map(k=>k.id)
      //选中的数组
      let chooseButtonArr=this.$refs.buttonTree.getCheckedKeys();
      //通过循环删除假节点id
      let finalButtonIds = chooseButtonArr.filter(function(value) {
        return fakeButtonIds.indexOf(value) === -1;
      });
      this.$axios({
        method: "POST",
        url: "/productGrade/updateGradePCModule",
        data: {
          gradeId: this.gradeId,
          moduleIds: this.$refs.moduleTree.getCheckedKeys().concat(this.$refs.moduleTree.getHalfCheckedKeys()),
          buttonIds: finalButtonIds,
        }
      }).then(response=>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '分配成功' : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1000,
        })
        if (flag) {
          setTimeout(this.close, 2000)
        }
      })
    }
  },
}
</script>

<style scoped>

</style>