<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" style="margin-bottom: 10px" @click="addOpen">添加产品等级</el-button>
      <el-table
          :data="productGradeList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          style="width: 100%">
        <el-table-column prop="gradeName" label="等级名称" align="center"/>
        <el-table-column align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="warning" size="mini"  @click="openPermission(scope.row)">分配电脑权限</el-button>
              <el-button type="info" size="mini"  @click="openMobilePermission(scope.row)">分配手机权限</el-button>
              <el-button type="success" size="mini"  @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini"  @click="remove(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
        v-if="addDialogState"
        title="添加产品等级"
        width="30%"
        :visible.sync="addDialogState"
        :modal-append-to-body="false"
        @close="addClose"
        center
    >
      <el-form label-width="100px" :model="form" :rules="rules" ref="addForm">
        <el-row>
          <el-col :span="21">
            <el-form-item label="产品名称" prop="gradeName">
              <el-input v-model="form.gradeName"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="addClose">取 消</el-button>
        <el-button size="medium" type="primary" @click="addSubmit">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
        v-if="editDialogState"
        title="修改产品等级"
        width="30%"
        :visible.sync="editDialogState"
        :modal-append-to-body="false"
        @close="editClose"
        center
    >
      <el-form label-width="100px" :model="editForm" :rules="rules" ref="editForm">
        <el-row>
          <el-col :span="21">
            <el-form-item label="产品名称" prop="gradeName">
              <el-input v-model="editForm.gradeName"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="editClose">取 消</el-button>
        <el-button size="medium" type="primary" @click="editSubmit">提 交</el-button>
      </span>
    </el-dialog>
    <gradePcPermission v-if="gradePcPermissionObj.state" :state="gradePcPermissionObj.state"
                       :gradeId="gradePcPermissionObj.id" @close="addClose"></gradePcPermission>
    <gradeMobilePermission v-if="gradeMobilePermissionObj.state" :state="gradeMobilePermissionObj.state"
                       :gradeId="gradeMobilePermissionObj.id" @close="addClose"></gradeMobilePermission>
  </div>
</template>

<script>
import gradePcPermission from "@/pages/view/permission/product-grade/grade-pc-permission"
import gradeMobilePermission from "@/pages/view/permission/product-grade/grade-mobile-permission"
export default {
  name: "product-grade-list",
  created() {
    this.queryGradeList()
  },
  data() {
    return {
      productGradeList: [],
      addDialogState: false,
      form: {
        gradeName: "",
      },
      rules: {
        gradeName: [
          {required: true, message: '请输入产品等级名称', trigger: 'blur'},
        ],
      },
      editDialogState: false,
      editForm: {
        gradeName: "",
      },
      gradePcPermissionObj:{
        state:false,
        id:1,
      },
      gradeMobilePermissionObj:{
        state:false,
        id:1,
      }

    }
  },
  components: {
    gradePcPermission,
    gradeMobilePermission,
  },
  methods: {
    queryGradeList() {
      this.$axios({
        method: "get",
        url: "/productGrade/queryProductGrade",
      }).then(response => {
        this.productGradeList = response.data.data
      })
    },
    openEdit(val) {
      this.editForm = val
      this.editDialogState = true
    },
    editClose() {
      this.editDialogState = false
    },
    remove(val) {
      this.$axios({
        method: 'post',
        url: '/productGrade/deleteProductGrade',
        data: {
          id: val.id
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("删除成功!")
          let self = this;
          setTimeout(function () {
            self.queryGradeList()
          }, 700)
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    addOpen() {
      this.addDialogState = true
    },
    addClose() {
      this.addDialogState = false
      this.gradePcPermissionObj.state=false;
      this.gradeMobilePermissionObj.state=false;
    },
    addSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/productGrade/addProductGrade",
            data: {
              gradeName: this.form.gradeName,
              createDate: new Date()
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success("添加成功!")
              let self = this;
              setTimeout(function () {
                self.addClose()
                self.queryGradeList()
              }, 700)
            } else {
              this.$message.error(response.data.msg)
            }
          })
        }
      })
    },
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/productGrade/editProductGrade",
            data: {
              id: this.editForm.id,
              gradeName: this.editForm.gradeName
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success("修改成功!")
              let self = this;
              setTimeout(function () {
                self.editClose()
                self.queryGradeList()
              }, 700)
            } else {
              this.$message.error(response.data.msg)
            }
          })
        }
      })
    },
    //打开分配电脑端视图
    openPermission(data){
      this.gradePcPermissionObj.state = true
      this.gradePcPermissionObj.id = data.id
    },
    //打开分配手机端视图
    openMobilePermission(data){
      this.gradeMobilePermissionObj.state = true
      this.gradeMobilePermissionObj.id = data.id
    },
  },
}
</script>

<style scoped>

</style>